import { team } from "../../services/api";

export const FETCH_TEAM_SUCCESS = 'FETCH_TEAM_SUCCESS';
export const FETCH_TEAM_FAILURE = 'FETCH_TEAM_FAILURE';


export const fetchTeams =()=>async(dispatch)=>{
	try {
		
        const response = await team(); 
        console.log('teem',response);
        dispatch({
            type: FETCH_TEAM_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: FETCH_TEAM_FAILURE,
            payload: error.message,
        });
    }
}

