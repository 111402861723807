import React from "react";
import { useSelector } from 'react-redux';
import { getImageUrl } from '../utils';
import { useEffect } from "react";
import separator from '../images/sep.png'
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";


const Service = () => {
    const { service_slug } = useParams();  // Capture the service_slug from the URL
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [service_slug]);

    const services = useSelector((state) => state.service.services);
    const service = services.find((s) => s.service_slug === service_slug);

    console.log(service);
    if (!service) {
        return <p>Service not found.</p>;  // Render a message if no service matches the slug
    }

    return (
        <>
            <section className="inner-banner py-5 position-relative wow animated fadeInDown">
                <em className="bg-black opacity-75 position-absolute top-0 end-0 start-0 bottom-0"></em>
                <div className="container py-md-5 py-4">
                    <div className="row">
                        <div className="col-12 text-center">
                            <nav aria-label="breadcrumb" className="position-relative z-1 d-flex justify-content-center">
                                <ol className="breadcrumb fs-5 mb-0 py-5">
                                    <li className="breadcrumb-item"><Link to="/" className="text-white">Home</Link></li>
                                    <li className="breadcrumb-item fw-bolder text-primary-dt" aria-current="page">{service.title}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <section className="service-details py-5 wow animated fadeInDown">
                <div className="container py-md-5 py-4">
                    <div className="row">
                        <div className="col-lg-3 col-md-4">
                            <aside className="bg-dark p-4 text-center text-white wow animated fadeInDown rounded-2 mb-5">
                                <h3 className="h4 fw-bold ff-josefine lh-150">Need Help? Talk With Expert</h3>
                                <hr />
                                <p className="text-light d-block py-2">Call Anytime</p>
                                <a href="tel:+923237988058" className="fs-5 fw-bold text-primary-dt d-block py-4">+92 323 7988058</a>
                            </aside>
                            <aside className="other-services p-4 rounded-2 shadow-sm border">
                                <h3 className="h5 fw-bold ff-josefine">Other Categories</h3>
                                <hr className="opacity-50" />
                                <ul className="services list-unstyled">
                                    <li className="my-1">
                                        {services && services.length > 0 ? (
                                            services.map((service) => (
                                                <Link to={`/service/${service.service_slug}`} className="py-3 d-block hvr-underline-from-left transition">
                                                    <i className="fa fa-angle-double-right me-2"></i> {service.title}
                                                </Link>
                                            ))
                                        ) : (
                                            <span>No services available</span>
                                        )}
                                    </li>
                                </ul>
                            </aside>
                        </div>
                        <div className="col-lg-9 col-md-8 ps-lg-5">
                            <img src={service.image} alt="Service Image" className="img-fluid rounded-2 w-100 border shadow-sm wow animated fadeInDown mb-4" />
                            
                            <p className="d-block lh-170 wow animated fadeInDown"
                            dangerouslySetInnerHTML={{
                            __html: service.description,
                          }}>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Service;