import React from "react";
import logo from "../../images/devio-tech-logo.png";
import { HashLink } from 'react-router-hash-link';
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom"; 
import { useState, useEffect } from "react";
import ThemeToggle from "../ThemeToggle";

const Header=()=>{
	const [activeSection, setActiveSection] = useState(""); 
	const location = useLocation();

	const handleScroll = () => {
		const sections = document.querySelectorAll("section");
		const scrollPosition = window.scrollY;
	
		sections.forEach((section) => {
		  const offsetTop = section.offsetTop - 100; 
		  const offsetHeight = section.offsetHeight;
	
		  if (scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight) {
			setActiveSection(section.id); 
		  }
		});
	  };

	  useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
		  window.removeEventListener("scroll", handleScroll); 
		};
	  }, []);
	  useEffect(() => {
		if (location.hash) {
		  setActiveSection(location.hash.substring(1)); 
		}
	  }, [location]);
	return(
	<>
		<nav className="navbar navbar-expand-lg bg-white shadow-sm border-bottom sticky-top z-2 wow animated fadeInDown">
			<div className="container">
				<Link className="navbar-brand" to="/"><img src={logo} alt="Logo" className="img-fluid dark-logo" width="230" /><img src="assets/images/logo-devio-tech-white.png" alt="Logo" className="img-fluid white-logo" width="230" /></Link>
				<button className="navbar-toggler btn-primary-dt py-2" type="button" data-bs-toggle="collapse" data-bs-target="#mainNavBar" aria-controls="mainNavBar" aria-expanded="false" aria-label="Toggle navigation"><i className="fa fa-bars"></i></button>
				<div className="collapse navbar-collapse" id="mainNavBar">
				<ul className="navbar-nav ms-auto mb-2 mb-lg-0">
					<li className="nav-item">
					<HashLink to="/#home" className={`nav-link fs-13 px-4 py-3 rounded-1 text-uppercase fw-bold hvr-shutter-out-horizontal transition ${activeSection === "home" ? "active" : ""}`}>Home</HashLink>
					</li>
					<li className="nav-item">
					<HashLink to="/#aboutUs" className={`nav-link fs-13 px-4 py-3 rounded-1 text-uppercase fw-bold hvr-shutter-out-horizontal transition ${activeSection === "aboutUs" ? "active" : ""}`}>About Us</HashLink>
					</li>
					<li className="nav-item">
					<HashLink to="/#ourServices" className={`nav-link fs-13 px-4 py-3 rounded-1 text-uppercase fw-bold hvr-shutter-out-horizontal transition ${activeSection === "ourServices" ? "active" : ""}`}>Our Services</HashLink>
					</li>
					<li className="nav-item">
					<HashLink to="/#clients" className={`nav-link fs-13 px-4 py-3 rounded-1 text-uppercase fw-bold hvr-shutter-out-horizontal transition ${activeSection === "clients" ? "active" : ""}`}>Clients</HashLink>
					</li>
					<li className="nav-item">
					<HashLink to="/#projects" className={`nav-link fs-13 px-4 py-3 rounded-1 text-uppercase fw-bold hvr-shutter-out-horizontal transition ${activeSection === "projects" ? "active" : ""}`}>Projects</HashLink>
					</li>
					<li className="nav-item">
					<HashLink to="/#achievements" className={`nav-link fs-13 px-4 py-3 rounded-1 text-uppercase fw-bold hvr-shutter-out-horizontal transition ${activeSection === "achievements" ? "active" : ""}`}>Achievements</HashLink>
					</li>
					<li className="nav-item">
					<HashLink to="/#getInTouch" className={`nav-link fs-13 px-4 py-3 rounded-1 text-uppercase fw-bold hvr-shutter-out-horizontal transition ${activeSection === "getInTouch" ? "active" : ""}`}>Get In Touch</HashLink>
					</li>
					<li className="nav-item">
					<HashLink to="/team" className={`nav-link fs-13 px-4 py-3 rounded-1 text-uppercase fw-bold hvr-shutter-out-horizontal transition ${location.pathname === "/team" ? "active" : ""}`}>Team</HashLink>
					</li>
					<ThemeToggle />
				</ul>
				</div>
			</div>
		</nav>
	</>);
}
export default Header;